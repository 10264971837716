;(function ($) {
  'use strict'
   $.fn.setActiveLinks = function (searchParams) {
    var y
    var filters = document.getElementById('filters').getElementsByTagName('a')
    var filters_number = filters.length
    if (searchParams.toString().length > 0) {
      var query_string = searchParams.toString().replace(/[^a-z0-9,. ]/gi, '')
    } else {
      var query_string = ''
    }
    if (query_string) {
      for (y = 0; y < filters_number; y++) {
        if (filters[y].id && query_string.indexOf(filters[y].id) > -1) {
          filters[y].classList.add('active')
        } else {
          filters[y].classList.remove('active')
        }
      }
    } else {
      for (y = 0; y < filters_number; y++) {
        filters[y].classList.remove('active')
      }
      document.getElementById('all1').classList.add('active')
    }
  }

  //Filter URL function
  $.fn.filterUrl = function (name, id) {
    name = typeof name !== 'undefined' ? name : ''
    id = typeof id !== 'undefined' ? id : ''
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search)
      if (searchParams.toString().length > 0) {
        if (searchParams.has(name) && name !== '') {
          if (searchParams.get(name) != id) {
            searchParams.delete(name)
            searchParams.set(name, id)
          } else {
            if (name !== '') {
              searchParams.delete(name)
            }
          }
        } else {
          if ((name !== '') & (id !== '')) {
            searchParams.set(name, id)
          }
        }
      } else {
        if ((name !== '') & (id !== '')) {
          searchParams.set(name, id)
        }
      }
      if (searchParams.has('all')) {
        var newRelativePathQuery = window.location.pathname
        searchParams = ''
      } else {
        if (searchParams.toString()) {
          var newRelativePathQuery =
            window.location.pathname + '?' + searchParams.toString()
        } else {
          var newRelativePathQuery = window.location.pathname
        }
      }
      history.pushState(null, '', newRelativePathQuery)
      $(this).setActiveLinks(searchParams)
    }

    //starting ajax call

    var urlParams = new URLSearchParams(window.location.search)
    var tagId, priority, store
    if (urlParams.has('tagId')) {
      tagId = urlParams.get('tagId')
    }
    if (urlParams.has('priority')) {
      priority = urlParams.get('priority')
    }
    if (urlParams.has('store')) {
      store = urlParams.get('store')
    }

    var dataSend = {
      tagId: tagId ? tagId : null,
      priority: priority ? priority : null,
      store: store ? store : null,
    }

    var url = 'ajax/filterproducts'

    $.ajax({
      type: 'POST',
      url: url,
      data: dataSend,
      beforeSend: function () {
        $('#overlay').fadeIn(300)
      },
      success: function (json) {
        if (json) {
          var i
          var data = json.products
          var tags = json.tagsCount
          var tbody = document.getElementById('packlistbody')
          while (tbody.firstChild) {
            // This will remove all children within tbody which in your case are <tr> elements
            tbody.removeChild(tbody.firstChild)
          }
          var html = ''
          if (Object.keys(data).length > 0) {
            var len = Object.keys(data).length
            for (i = 0; i < len; i++) {
              html += '<tr id="' + data[i].id + '">'
              if (data[i].imageUrl) {
                html +=
                  '<td><img src="' +
                  data[i].imageUrl +
                  '" alt="' +
                  data[i].name +
                  '"/></td>'
              } else {
                html +=
                  '<td><img src="build/img/no-image.webp" alt="' +
                  data[i].name +
                  '"/></td>'
              }
              if (data[i].options !== false) {
                html += '<td class="text-left">' + data[i].name + '<br>'
                data[i].options.forEach(function (value, index) {
                  html += '<small>'
                  html +=
                  '<strong>' +value.name + '</strong>: ' + value.value + '<br>'
                  html += '</small>'
                })
                html += '</td>'
              } else {
                html += '<td class="text-left">' + data[i].name + '</td>'
              }
              html += '<td><a href="#" class="sku" data-type="textarea" data-name="sku" data-pk="'+ data[i].id + '" data-url="/ajax/'+ data[i].id +'/addnote" data-title="Enter your note">'+ data[i].sku + '</a></td>'
              if (data[i].barcodeimage) {
                html +=
                  '<td class="barcode"><img src="' +
                  data[i].barcodeimage +
                  '" alt="' +
                  data[i].name +
                  '"/></td>'
              } else {
                html +=
                  '<td class="barcode"><img src="build/img/no-barcode.webp" alt="' +
                  data[i].name +
                  '"/></td>'
              }
              html +=
                '<td class="font-weight-bold h5">' + data[i].quantity + '</td>'
              html += '<td class="no-border">&nbsp;</td>'
              if (data[i].issue == 1) {
                if (data[i].quantity > 1) {
                  /*
                  html +=
                    '<td class="padding-0 font-12 bg-orange" style="width:160px"><a class="btn full disabled" href="javascript:void(0);" data-productid="' +
                    data[i].productid +
                    '" data-id="' +
                    data[i].id +
                    '" data-quantity="' +
                    data[i].quantity +
                    '" data-orders="' +
                    data[i].orderids +
                    '" data-items="' +
                    data[i].orderitemids +
                    '" data-swipe="1000">MADE ALL</a></td>'
                    */
                } else {
                  /*
                  html += '<td class="" style="width:160px">&nbsp;</td>'
                  */
                }
                html +=
                  '<td class="padding-0 font-12 bg-green" style="width:120px"><a class="btn full disabled" href="javascript:void(0);" data-productid="' +
                  data[i].productid +
                  '" data-id="' +
                  data[i].id +
                  '" data-quantity="1" data-orders="' +
                  data[i].orderids +
                  '" data-items="' +
                    data[i].orderitemids +
                  '" data-swipe="1000">MADE</a></td>'
                html += '<td class="no-border" style="width:70px">&nbsp;</td>'
                html +=
                  '<td class="padding-0 font-12 bg-red" style="width:120px"><a class="btn full" href="javascript:void(0);" data-productid="' +
                  data[i].productid +
                  '" data-id="' +
                  data[i].id +
                  '" data-quantity="' +
                  data[i].quantity +
                  '" data-orders="' +
                  data[i].orderids +
                  '" data-items="' +
                    data[i].orderitemids +
                  '" data-issue="0" data-swipe="1000">ISSUE</a></td>'
              } else {
                if (data[i].quantity > 1) {
                  /*
                  html +=
                    '<td class="padding-0 font-12 bg-orange" style="width:160px"><a class="btn full" href="javascript:void(0);" data-productid="' +
                    data[i].productid +
                    '" data-id="' +
                    data[i].id +
                    '" data-quantity="' +
                    data[i].quantity +
                    '" data-orders="' +
                    data[i].orderids +
                    '" data-items="' +
                    data[i].orderitemids +
                    '" data-swipe="1000">MADE ALL</a></td>'
                    */
                } else {
                  /*
                  html += '<td class="" style="width:160px">&nbsp;</td>'
                  */
                }
                html +=
                  '<td class="padding-0 font-12 bg-green" style="width:120px"><a class="btn full" href="javascript:void(0);" data-productid="' +
                  data[i].productid +
                  '" data-id="' +
                  data[i].id +
                  '" data-quantity="1" data-orders="' +
                  data[i].orderids +
                  '" data-items="' +
                    data[i].orderitemids +
                  '" data-swipe="1000">MADE</a></td>'
                html += '<td class="no-border" style="width:70px">&nbsp;</td>'
                html +=
                  '<td class="padding-0 font-12 bg-light-grey" style="width:120px"><a class="btn full" href="javascript:void(0);" data-productid="' +
                  data[i].productid +
                  '" data-id="' +
                  data[i].id +
                  '" data-quantity="' +
                  data[i].quantity +
                  '" data-orders="' +
                  data[i].orderids +
                  '" data-items="' +
                    data[i].orderitemids +
                  '" data-issue="1" data-swipe="1000">ISSUE</a></td>'
              }
              html += '</tr>'
            }
          } else {
            html += '<tr>'
            html +=
              '<td colspan="10"><h2 class="py-4 text-center">No products found</h2></td>'
            html += '</tr>'
          }
          document.getElementById('packlistbody').innerHTML = html
          if (tags) {
            var x
            for (x = 0; x < Object.keys(tags).length; x++) {
              if (
                tags[x].tagId !== 'undefined' &&
                document.getElementById(tags[x].tagId)
              ) {
                document.getElementById(tags[x].tagId).innerHTML =
                  '(' + tags[x].totalProducts + ')'
              }
            }
          }
        } else {
          console.log('No json from the response')
        }
      },
      complete: function () {
        $('.sku').editable();
        setTimeout(function () {
          $('#overlay').fadeOut(300)
        }, 500);
      },
      error: function (jqXHR, exception) {
        var msg = ''
        if (jqXHR.status === 0) {
          msg = 'Not connect.\n Verify Network.'
        } else if (jqXHR.status == 404) {
          msg = 'Requested page not found. [404]'
        } else if (jqXHR.status == 500) {
          msg = 'Internal Server Error [500].'
        } else if (exception === 'parsererror') {
          msg = 'Requested JSON parse failed.'
        } else if (exception === 'timeout') {
          msg = 'Time out error.'
        } else if (exception === 'abort') {
          msg = 'Ajax request aborted.'
        } else {
          msg = 'Uncaught Error.\n' + jqXHR.responseText
        }
        console.log(msg)
      },
    })
    //end ajax call
  }
  // listen for touchend event
  var ele = document.getElementById('packlist');
    ele.addEventListener('click', function (e) {
      if(e.target.dataset.swipe){
      var dataSend = {
        productid: parseInt(e.target.dataset.productid),
        orderitemids: parseInt(e.target.dataset.items),
        quantity: e.target.dataset.quantity,
        orderids: e.target.dataset.orders,
        issue: e.target.dataset.issue,
      }
      var original_text = e.target.innerHTML
      if (e.target.dataset.issue) {
        var url = 'ajax/setissue'
      } else {
        var url = 'ajax/makeproducts'
      }
      var myRow = e.target.parentNode.parentNode
      var quantityCel = myRow.getElementsByTagName('td')[4]
      //var quantityCelMadeAll = myRow.getElementsByTagName('td')[6]
      var quantityCelMade = myRow.getElementsByTagName('td')[6]
      var issueCel = myRow.getElementsByTagName('td')[8]
      $.ajax({
        type: 'POST',
        url: url,
        data: dataSend,
        beforeSend: function () {
          e.target.innerHTML = e.target.innerHTML = 'WORKING...'
          e.target.parentNode.classList.remove(
            'bg-grey',
            'bg-red',
            'bg-orange',
            'bg-green',
          )
          e.target.parentNode.classList.add('bg-dark-grey')
        },
        success: function (json) {
          var new_quantity = json.product_data.quantity
          var new_orders = json.product_data.orders
          var new_items = json.product_data.orderitemids
          var issue = json.product_data.issue
          var tags = json.tagsCount
          issueCel.classList.remove('bg-dark-grey')
          if (e.target.dataset.issue) {
            if (issue && issue.issue == 1) {
              issueCel.classList.add('bg-red')
              issueCel
                .getElementsByTagName('a')[0]
                .setAttribute('data-issue', 0)
              if (quantityCelMadeAll.getElementsByTagName('a')[0]) {
                quantityCelMadeAll
                  .getElementsByTagName('a')[0]
                  .classList.add('disabled')
                quantityCelMadeAll.classList.add('disabled')
              }
              quantityCelMade
                .getElementsByTagName('a')[0]
                .classList.add('disabled')
              quantityCelMade.classList.add('disabled')
              e.target.innerHTML = original_text
            }
            if (issue && issue.issue == 0) {
              issueCel.classList.add('bg-grey')
              issueCel
                .getElementsByTagName('a')[0]
                .setAttribute('data-issue', 1)
              if (quantityCelMadeAll.getElementsByTagName('a')[0]) {
                quantityCelMadeAll
                  .getElementsByTagName('a')[0]
                  .classList.remove('disabled')
                quantityCelMadeAll.classList.add('disabled')
              }
              quantityCelMade
                .getElementsByTagName('a')[0]
                .classList.remove('disabled')
              quantityCelMade.classList.remove('disabled')
              e.target.innerHTML = original_text
            }
          } else {
            if (new_quantity == 0) {
              e.target.innerHTML = 'PRODUCT IS MADE<br>REMOVING ROW...'
              e.target.parentNode.parentNode.classList.add('active')
              setInterval(function () {
                e.target.parentNode.parentNode.classList.remove('active')
                e.target.parentNode.parentNode.remove()
              }, 3000)
            } else {
              e.target.innerHTML = original_text
              quantityCelMade.classList.remove('bg-dark-grey')
              quantityCel.innerText = new_quantity
              if (quantityCelMadeAll.getElementsByTagName('a')[0]) {
                if (new_quantity > 1) {
                  quantityCelMadeAll
                    .getElementsByTagName('a')[0]
                    .setAttribute('data-quantity', new_quantity)
                    quantityCelMadeAll
                    .getElementsByTagName('a')[0]
                    .setAttribute('data-orders', new_orders)
                    quantityCelMadeAll
                    .getElementsByTagName('a')[0]
                    .setAttribute('data-items', new_items)
                  quantityCelMade.classList.add('bg-green')
                } else {
                  quantityCelMadeAll.innerHTML = ''
                  quantityCelMadeAll.classList.remove('bg-orange')
                  quantityCelMade.classList.add('bg-green')
                }
             }
            }
          }
          if (tags) {
            var x
            var tagslen = Object.keys(tags).length
            for (x = 0; x < tagslen; x++) {
              document.getElementById(tags[x].tagId).innerHTML =
                '(' + tags[x].totalProducts + ')'
            }
          }
          if (document.getElementById('packlistbody').rows.length == 0) {
            var html = '<tr>'
            html +=
              '<td colspan="10"><h2 class="py-4 text-center">No products found</h2></td>'
            html += '</tr>'
            document.getElementById('packlistbody').innerHTML = html
          }
        },
        complete: function () {},
        error: function (jqXHR, exception) {
          var msg = ''
          if (jqXHR.status === 0) {
            msg = 'Not connect.\n Verify Network.'
          } else if (jqXHR.status == 404) {
            msg = 'Requested page not found. [404]'
          } else if (jqXHR.status == 500) {
            msg = 'Internal Server Error [500].'
          } else if (exception === 'parsererror') {
            msg = 'Requested JSON parse failed.'
          } else if (exception === 'timeout') {
            msg = 'Time out error.'
          } else if (exception === 'abort') {
            msg = 'Ajax request aborted.'
          } else {
            msg = 'Uncaught Error.\n' + jqXHR.responseText
          }
          console.log(msg)
        },
      })
    }
    })
    //x-editable mode
    $.fn.editable.defaults.mode = 'inline';

  $(document).ready(function () {
    $('.sku').editable();
    var options = new URLSearchParams(window.location.search)
    $.fn.setActiveLinks(options)
    setInterval(function () {
      $.fn.filterUrl()
    }, 3600000)
  })
})(jQuery)
