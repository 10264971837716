/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
require('./js/bootstrap-editable.min.js')
require('./js/swipe.js')
require('./js/frontend-scripts.js')
// any CSS you import will output into a single css file (app.css in this case)
//import './styles/app.css';

import './css/bootstrap-editable.css';
import './css/style.css';

// start the Stimulus application
import './bootstrap';
